// import useJwt from '@core/auth/jwt/useJwt'
// import axios from '@axios'

// const { jwt } = useJwt(axios, {})
// export default jwt

import JwtService from './jwtService'

export default function useJwt(axiosIns, jwtOverrideConfig) {
  const jwt = new JwtService(axiosIns, jwtOverrideConfig)

  return {
    jwt,
  }
}
