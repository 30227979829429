import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

Vue.component(FeatherIcon.name, FeatherIcon)

// register all Bootstrap Vue Components
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import flatPickr from 'vue-flatpickr-component'
Vue.component('flat-pickr', flatPickr)

import pagination from 'laravel-vue-pagination'
Vue.component('pagination', pagination)