import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import {regex } from 'vee-validate/dist/rules'
import * as rules from 'vee-validate/dist/rules';

export function loadLocale(code) {

    return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
        localize(code, locale);
    });
}

extend('alpha_num_spaces', {
    ...regex,
    message: 'The {_field_} field may only contain letters, numbers, and spaces.',
    validate: value => {
    return  /^[a-zA-Z0-9\u0621-\u064A\s]+$/.test(value);
    }
  });
  
  extend("mobile", {
    ...regex,
    message: "الرجاء إدخال رقم جوال صحيح"
});

// Set default language
loadLocale('ar');

// Install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);