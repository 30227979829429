<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLocale(localeObj)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { loadLocale } from '@/libs/vee-validate/index'
import store from '@/store'

export default {
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  methods: {
    changeLocale(localeObj) {
      console.log(localeObj)
      this.$i18n.locale = localeObj.locale
      localStorage.setItem('locale', JSON.stringify(localeObj))
      loadLocale(this.$i18n.locale)
      store.commit('appConfig/TOGGLE_RTL', localeObj.isRTL)
    }
  },
  created() {
    this.$i18n.locale = JSON.parse(localStorage.getItem('locale'))?.locale || $themeConfig.layout.locale.locale
    // store.commit('appConfig/TOGGLE_RTL', JSON.parse(localStorage.getItem('locale'))?.isRTL || $themeConfig.layout.locale.isRTL)
    store.commit('appConfig/TOGGLE_RTL', this.$i18n.locale === 'en' ? false : true )
  },

  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'ar',
        img: require('@/assets/images/flags/sa.png'),
        name: 'عربي',
        isRTL: true
      },
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
        isRTL: false
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
}
</script>

<style>

</style>
