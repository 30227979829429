// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  // return localStorage.getItem('accessToken') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  return localStorage.getItem('accessToken')
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const currentUserRole = () => {
  const accessToken = localStorage.getItem('accessToken') || store.state.token
  const decodedAccessToken = accessToken ? jwt_decode(store.state.token || accessToken) : null
  // console.log(decodedAccessToken?.user, ' decodedAccessToken')
  if (decodedAccessToken) store.commit('app/UPDATE_USER_ROLE', decodedAccessToken?.user?.roles ? decodedAccessToken?.user?.roles[0] : decodedAccessToken?.user?.roles)
  return decodedAccessToken?.user?.roles ? decodedAccessToken?.user?.roles[0] : decodedAccessToken?.user?.roles
  // ,decodedAccessToken.user.roles[0].toLocaleLowerCase().includes('admin')
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}
